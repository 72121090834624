.sticky-text-bar {
    position: fixed;
    padding: 20px 8px;
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    text-align: center;
    background: red;
    border-bottom: 1px solid #f9f5f8;
    z-index: 999;
    //animate
    transition: .6s ease-in-out;
    transform: translateY(-100%);
    opacity: 0;

    @media (min-width:769px) {
        background: #625066;
    }
}

.animeTop {
    transform: translateY(0);
    opacity: 1;
}