.quote-form {

    &__heading {
        font-weight: bold;
        font-size: 34px;
        line-height: 44px;
        margin-bottom: 30px;
        text-align: center;
        letter-spacing: -1px;
    }

    &__footer-line,
    &__second-footer-line {
        background: var(--first-color);
        height: 45px;
    }

    &__second-footer-line {
        margin-top: 6px;
        display: none;
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    &__image {
        width: 100%;
        display: block;
        position: relative;
        top: 36px;
    }

    &__image-wrapper {
        display: none;
    }

    &__form-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;

        .input:last-of-type {
            margin-bottom: 30px;
        }
    }

    &__form {
        max-width: 300px;
        text-align: center;
    }

    &__voltarAoCombo{
        margin-bottom: 30px;
        display: inline-block;
        text-decoration: none;
        border-bottom: 1px solid;
        cursor: pointer;
    }
    
    &__info-error {
        font-size: 16px;
        margin-bottom: 30px;
        color: red;
        background: #f6d8da;
        border-radius: 30px;
        padding: 20px 10px;
        font-weight: 500;
    }

    &__info-add {
        margin-top: 6px;
        font-size: 14px;
        color: brown;
    }

    &__info-local-atendimento {
        font-weight: 600;
    }
    
    &__info-total{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        color: #000;
        span {
            font-size: 16px;
            font-weight: bold;
            color: #7070f7;
        }
    }

    &__info-click {
        font-size: 16px;
        line-height: 20px;
    }

    @media (min-width:769px) {

        background: var(--third-background);

        &__second-footer-line {
            display: block;
        }

        &__image-wrapper {
            display: block;
            width: 35%;
        }

        &__form-wrapper {
            width: 65%;
            margin-top: 80px;
        }
    }

}