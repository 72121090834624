.feature-row {
    
    background: var(--second-background);
    display: flex;
    width: 100%;
    padding: 20px 20px 0;

    .container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &-image-wrapper {
        width: 50%;
        max-width: 220px;

        img {
            width: 100%;
            display: block;
            position: relative;
            top: 7px;
        }
    }

    &-text-wrapper {        
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h2 {
            font-size: 22px;
            line-height: 36px;
            text-transform: uppercase;
        }

        p {
            font-size: 20px;
            line-height: 50px;
        }

    }

    @media (min-width:769px) {

        padding: 40px 40px 0;

        &-image-wrapper {
            max-width: 280px;
        }

        &-text-wrapper { 
            max-width: 590px;

            h2 {
                font-size: 40px;
                line-height: 56px;
                letter-spacing: -1px;
            }
    
            p {
                font-size: 36px;
                line-height: 56px;
            }
    
        }
    }

    @media (max-width:360px) {
        &-text-wrapper { 
            h2 {
                font-size: 18px;
                line-height: 28px;
            }
    
            p {
                font-size: 16px;
                line-height: 50px;
            }
        }
    }
}