.sobre-nos {
    padding: 70px 20px 80px;
    display: flex;
    justify-content: center;

    &__wrapper{
        max-width: 600px;
    }

    h2 {
        font-size: 32px;
        line-height: 56px;
        letter-spacing: -1px;
        text-align: center;
    }

    p {
        font-size: 16px;
        line-height: 36px;
        margin-top: 20px;
        text-align: center;
    }

    @media (min-width:769px) {
        padding: 100px 0 140px;

        h2 {
            font-size: 56px;
            line-height: 56px;
        }
    
        p {
            font-size: 20px;
            line-height: 36px;
            margin-top: 52px;
        }
    }


}