:root {
  --first-color: #58305F;
  --second-color: #CCC3CE;
  --text-color: #101010;
  --background: #FFFFFF;
  --second-background: #E6E0E7;
  --third-background: #F5F3F5;
  --max-width-container: 1310px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// font-size: 16px (Desktop)
html {
  @media (max-width: 1080px) {
    font-size: 93.75%; // 15px
  }

  @media (max-width: 720px) {
    font-size: 87.5%; // 14px
  }
}

body {
  background: var(--background);
  -webkit-font-smoothing: antialiased;
}

body, input, textarea, button {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 600;
}

button {
  cursor: pointer;
}

[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.container {
  max-width: var(--max-width-container);
  margin: 0 auto;
}