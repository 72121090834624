.quote-products {

    padding: 70px 20px 35px;
    overflow-x: hidden;

    &__header {
        max-width: 310px;
        margin: 0 auto;

        h2 {
            font-size: 36px;
            line-height: 56px;
            text-align: center;
            letter-spacing: -1px;
        }
        p {
            font-size: 20px;
            line-height: 20px;
            font-weight: 200;
            text-align: end;
            letter-spacing: -1px;
            position: relative;
            top: -10px;
        }
    }

    &__items {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
    }

    &__total {
        margin-top: 30px;
        font-size: 16px;
        min-height: 20px;
        color: #c780c7;
        font-weight: bold;
    }

    &-loader {
        position: absolute;
    }
    
    @media (min-width:769px) {
        padding: 130px 50px 130px;

        &__header {
            max-width: 480px;
            h2 {
                font-size: 56px;
            }
            p {
                font-size: 20px;
                line-height: 20px;
                top: 0px;
                padding-right: 10px;
            }
        }

        &__items {
          margin-top: 80px;
        }

        &__total {
            margin-top: 50px;
        }
    }

    .swiper {
        width: 240px;
        height: 320px;
    }

    .swiper-slide-shadow {
        border-radius: 18px;
    }
    
    .swiper-3d .swiper-wrapper {
        -moz-transform-style: unset;
    }

}